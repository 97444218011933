import styled from "styled-components";
import { PrismicImage } from '@prismicio/react'
import {theme} from "../../styles/globalStyles"
import { media } from "../../utils/style-utils";

export const Container = styled.div`
    display: flex;
	flex-direction: column;
	margin: 80px 16px;
    gap: 40px;
    max-width: 1440px;

    @media screen and (min-width: 760px) {
		margin: 80px 40px;
	}

    @media screen and (min-width: 960px) {
        margin: 120px 80px;
    }

    @media screen and (min-width: 1280px) {
        margin: 120px;
    }

    @media screen and (min-width: 1680px) {
        margin: 120px auto;
    }
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    * {
        font-family: AdobeCaslonPro, serif;
        color: ${theme.colors.grey800};
        font-size: 18px;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 40px;
        line-height: 60px;
        color: ${theme.colors.goldenGrey600};

        ${media.phone`
            font-size: 32px;    
        `}
    }
`

export const BrandGridContainer = styled.div<{$columns: string}>`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;

    @media screen and (min-width: 740px) {
        grid-template-columns: repeat(${({$columns}) => $columns}, 1fr);
        column-gap: 80px;
        row-gap: 48px;
        padding: 58px 0;
    } 
`

export const StyledImage = styled(PrismicImage)`
    width: 100%;
    height: 125px;
    object-fit: contain;
`



