import React from 'react'
import { IBaseSlice, IPrismicImage } from '../../types/prismic/baseTypes'
import { IPrismicLink } from '../../types/prismic/baseTypes'
import { RichTextField } from '@prismicio/types'
import { PrismicRichText } from '@prismicio/react'
import {
	ImageContainer,
	StyledImage,
	TextContainer,
	TextContent,
	Title,
	TopText,
	ArrowContainer,
	ContinueLabel,
	Card,
} from './style'
import { useRouter } from 'next/router'
import KvikaArrow from '../icons/KvikaArrow'
import { resolvePrismicLink } from '../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../utils/prismic/Context'

export interface IContentCard extends IBaseSlice {
	primary: {
		card_title: string
		card_description: RichTextField
		cardtoptext?: string
		image: IPrismicImage
		imageonright: boolean
		link: IPrismicLink
		fullwidth: boolean
	}
	slice_type: 'contentcard'
}

type Props = {
	sliceData: IContentCard
}

const ContentCard: React.FC<Props> = ({ sliceData }) => {
	const router = useRouter()
	const { pages } = usePrismicRoutingContext()
	const link = resolvePrismicLink(sliceData.primary.link, pages)

	const handleCardClick = () => {
		if (link) {
			router.push(link)
		}
	}

	return (
		<Card $fullWidth={sliceData.primary.fullwidth}>
			<TextContainer $imageOnRight={sliceData.primary.imageonright} $fullWidth={sliceData.primary.fullwidth}>
				{sliceData.primary.cardtoptext && (
					<TopText>{sliceData.primary.cardtoptext}</TopText>
				)}
				<TextContent onClick={handleCardClick}>
					<Title>{sliceData.primary.card_title}</Title>
					<PrismicRichText field={sliceData.primary.card_description} />
					{link && (
						<ArrowContainer>
							<ContinueLabel>Nánar</ContinueLabel>
							<KvikaArrow />
						</ArrowContainer>
					)}
				</TextContent>
			</TextContainer>
			{sliceData.primary.image.url && (
				<ImageContainer $imageOnRight={sliceData.primary.imageonright}>
					<StyledImage
						src={sliceData.primary.image.url}
						alt='card-alt'
						width={0}
						height={0}
						sizes='100vw'
					/>
				</ImageContainer>
			)}
		</Card>
	)
}

export default ContentCard
