import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { RichTextBlock } from 'prismic-reactjs'
import { media } from '../../../utils/style-utils'
import {
	IBaseSlice,
	IPrismicImage,
	IPrismicLink,
} from '../../../types/prismic/baseTypes'
import { resolvePrismicLink } from '../../../utils/prismic/RouteHelpers'
import { usePrismicRoutingContext } from '../../../utils/prismic/Context'
import RichText from '../../RichText/RichText'
import ClippedRectangle from '../../../../public/svg/ClippedRectangle.svg'
import Image from 'next/image'
import { PrismicDocument } from '@prismicio/types'
import { theme } from '../../../styles/globalStyles'

type Colors = 'darkGold' | 'lightGold' | 'gold50'

export interface IHalfImageDescriptionSlice extends IBaseSlice {
	primary: {
		background: IPrismicImage
		heading?: string
		heading_color?: 'default' | 'gold'
		description?: RichTextBlock[] | string
		imageleft?: boolean
		image_style?: Colors
		image_first_on_mobile?: boolean
		video?: PrismicDocument
		desaturate: boolean
	}
	items: {
		link_button: IPrismicLink
		button_text: string
	}[]
	slice_label?: string
	slice_type: 'halfimagedescription'
}

const HalfImageDescription: React.FC<IHalfImageDescriptionSlice> = ({
	primary,
	items,
}) => {
	const { pages } = usePrismicRoutingContext()
	const reverse = primary?.imageleft
	const isDescriptionRichText = typeof primary?.description === 'object'
	const buttonValid = items.find((item) => item.button_text);
	
	return (
		<Container $reverse={reverse} $color={primary.image_style}>
			<TextContainer $reverse={reverse}>
				<SCCaptionHeading
					headingColor={primary.heading_color}
				>
					{primary?.heading}
				</SCCaptionHeading>
				{isDescriptionRichText ? (
					<RichTextWrapper>
						<RichText content={primary.description as RichTextBlock[]} />
					</RichTextWrapper>
				) : (
					<SCCaptionDescription className='primary'>
						{primary?.description as string}
					</SCCaptionDescription>
				)}
				{buttonValid && 
				<ButtonsContainer>
					{items?.map(
						(item, index) =>
							item.button_text && (
								<Link
								key={index}
								href={resolvePrismicLink(item.link_button, pages) ?? '#'}
								legacyBehavior
								>
									<SliceButton>{item.button_text}</SliceButton>
								</Link>
							)
						)}
					</ButtonsContainer>
					}
				<Image src={ClippedRectangle} alt='clipped rect' width={24} height={4} />
			</TextContainer>
			<MediaContainer>
				{primary.desaturate  && <Overlay  />}
				{primary.video?.url ? (
					<StyledVideo autoPlay muted loop>
						<source src={primary.video.url ?? ''} />
					</StyledVideo>
				) : (
					<StyledImage src={primary.background.url}
						width={0}
						height={0}
						sizes='100vw'
					/>
				)}
			</MediaContainer>
		</Container>
	)
}

export default HalfImageDescription

const Container = styled.div<{ $reverse?: boolean, $color?: Colors }>`
	display: grid;
	grid-template-columns: 1fr 1fr;
	background-color: ${({$color}) => $color ? `${theme.colors[`${$color}`]}` : 'inherit'};

	@media screen and (min-width: 960px) {
		${({$reverse}) => $reverse && `
			& > div:first-child {
				order: 1;
			}
			& > div:last-child {
				order: 0;
			}
		`}
	}
`

const ButtonsContainer = styled.div`
	justify-content: space-between;
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;

	button {
		cursor: pointer;
	}

	${media.tablet`
		flex-direction: column;
	`}
`

const SliceButton = styled.button`
	flex-grow: 1;
	background-color: transparent;
	border: 2px solid ${({ theme }) => theme.colors.gold550};
	color: ${({ theme }) => theme.colors.gold550};
	border-radius: 2px;
	padding: 16px 32px;

	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1rem;

	&:hover {
		background-color: ${({ theme }) => theme.colors.gold550};
		color: white;
	}
`

const TextContainer = styled.div<{$reverse?: boolean}>`
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-self: center;
	grid-column: 1/-1;
	margin: 0px 16px;
	padding: 96px 0;

	@media screen and (min-width: 760px) {
		margin: 0px 40px;
	}

	@media screen and (min-width: 960px) {
		grid-column: inherit;
		margin: ${props => props.$reverse ? "0 120px 0 60px" :  "0 40px 0 80px"};
	}

	@media screen and (min-width: 1280px) {
		margin: ${props => props.$reverse ? "0 120px 0 60px" :  "0 60px 0 120px"};
	}

	@media screen and (min-width: 1680px) {
		width: 660px; // 1440px / 2 - 60px
		margin: ${props => props.$reverse ? "0 auto 0 60px" :  "0 60px 0 auto"};
	}
`

const SCCaptionHeading = styled.h1<{
	headingColor?: 'default' | 'gold' | 'grey'
}>`
	font-weight: 600;
	font-size: 48px;
	line-height: 56px;
	margin: 0;
	padding: 0;
	font-family: AdobeCaslonPro, serif;
	font-weight: 400 !important;

	color: ${({ theme, headingColor }) =>
		headingColor === 'gold'
			? theme.colors.gold550
			: headingColor === 'grey'
			? theme.colors.grey800
			: 'inherit'};

	@media screen and (max-width: 740px) {
		font-size: 42px;
	}
`
const SCCaptionDescription = styled.div`
	font-size: 1.375rem;
	line-height: 2rem;
	font-family: AdobeCaslonPro, serif;
	font-weight: 400;
	margin: 0;
	padding: 0;

	${media.phone`
		font-size: 1.125rem;
	`}
`

const RichTextWrapper = styled.div`
	* > p {
		font-size: 22px;
		line-height: 2rem;
		font-family: AdobeCaslonPro, serif;
		font-weight: 400;
		color: ${theme.colors.grey800};
		padding: 0;
		margin: 0;

		@media screen and (max-width: 740px) {
			font-size: 20px;
		}
	}

	* > ul {
		font-size: 1.25rem;
		line-height: 2.5rem;
		font-family: AdobeCaslonPro, serif;
		line-height: 1.2;
		color: ${theme.colors.grey800};

		${media.phone`
			padding-left: 0;
		`}
	}
`

const MediaContainer = styled.div`
	display: flex;
	height: 380px;
	position: relative;
	grid-column: 1/-1;

	@media screen and (min-width: 960px) {
		height: 760px;
		grid-column: inherit;
	}
`

const StyledVideo = styled.video`
	height: 100%;
	width: 100%;
	object-fit: cover;
`

const StyledImage = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
`

const Overlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: #0000009C;
	mix-blend-mode: saturation;
`
