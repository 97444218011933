import styled from 'styled-components'
import Image from 'next/image';
import {theme} from "../../styles/globalStyles";

export const Card = styled.div<{$fullWidth: boolean}>`
    display: flex;
	flex-direction: column;
    gap: 40px;
	margin: 80px 16px;

    @media screen and (min-width: 760px) {
        gap: 80px;
        margin: 80px 40px;
    }

    @media screen and (min-width: 960px) {
        min-height: 646px;
        display: grid;
		grid-template-columns: 1fr 1fr;
        gap: 120px;
        margin: 120px 80px;
    }

    @media screen and (min-width: 1280px) {
        margin: 120px;
    }

    @media screen and (min-width: 1680px) {
        min-height: 680px;
        margin: 120px auto;
        width: 1440px;
    }

    ${props => props.$fullWidth && `
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;

        @media screen and (min-width: 960px) {
            gap: 0 !important;
        }
    `}
`

export const ImageContainer = styled.div<{$imageOnRight: boolean}>`
    height: 100%;
    flex: 1;
    order: -1;
    min-height: 400px;

    @media screen and (min-width: 760px) {
        order: ${({$imageOnRight}) => $imageOnRight ? '1' : '-1'};
    }
`   

export const TextContainer = styled.div<{$imageOnRight: boolean, $fullWidth: boolean}>`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    order: 1;

    @media screen and (min-width: 760px) {
        order: ${({$imageOnRight}) => $imageOnRight ? '-1' : '1'};
    }

    ${props => props.$fullWidth && `
    	margin: 0 16px;

        @media screen and (min-width: 760px) {
            margin: 0 40px;
        }

        @media screen and (min-width: 960px) {
            margin: ${props.$imageOnRight ? "0 40px 0 80px" : "0 120px 0 60px"};
        }
        
        @media screen and (min-width: 1280px) {
            margin: ${props.$imageOnRight ? "0 60px 0 120px" : "0 120px 0 60px"};
        }
        
        @media screen and (min-width: 1680px) {
            width: 660px; // 1440px / 2 - 60px
            margin: ${props.$imageOnRight ?  "0 60px 0 auto" : "0 auto 0 60px"};
        }`
    }
`

export const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (min-width: 740px) {
        gap: 8px;
    }

    @media screen and (min-width: 1280px) {
        gap: 32px;
    }

    > * {
        font-family: AdobeCaslonPro, serif;
        color: ${theme.colors.grey800};
        font-size: 22px;
        line-height: 40px;
    }

    &:hover {
        cursor: pointer;

        > h1 {
            text-decoration: underline ${theme.colors.gold550};
        }

        .arrowContainer {
            > div {
                color: ${theme.colors.gold550};
            }
            > svg  {
                > path {
                    fill: ${theme.colors.gold550};
                }
                transform: translateX(12px);
                transition: transform .3s ease-out;
            }
        }
    }


    > h1 {
        text-decoration: underline rgba(255, 255, 255, 0);
        transition: text-decoration .3s ease-out;
    }

    .arrowContainer {
        > svg  {
            transform: translateX(0x);
            transition: transform .3s ease-out;
        }
    }
`

export const Title = styled.h1`
    font-size: 40px;
    line-height: 48px;
    font-family: AdobeCaslonPro, serif;
    color: ${theme.colors.goldenGrey600};
    margin: 0;
    font-weight: 400 !important;
`

export const TopText = styled.div`
    font-family: 'AkzidenzGroteskPro', serif;
    color: ${theme.colors.gold400};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;

    @media screen and (min-width: 960px) {
        position: absolute;
        top: 0;
    }
`

export const StyledImage = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

export const ArrowContainer = styled.div.attrs({
    className: "arrowContainer"
})`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 24px;

    @media screen and (min-width: 740px) {
        margin-top: 32px;
    }

    @media screen and (min-width: 1280px) {
        margin-top: 8px;
    }
`

export const ContinueLabel = styled.div`
    font-weight: 500;
	font-family: 'AkzidenzGroteskPro', serif;
    color: ${theme.colors.gold300};
    font-size: 14px;
`

